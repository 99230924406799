import { ToMoneyFormat } from "../../../../../utils/Helper";
import {
  addTableBodyBorder,
  addTableHeader,
  addTableHeaderBorder,
  drawBody,
} from "../InvoiceGeneratorUtils";

const PDF_TAGIHAN_NON_KODE = (data: any[]) => {
  const header = [
    {
      title: "NO",
      style: {
        width: 10,
      },
    },
    {
      title: "TANGGAL",
      style: {
        width: 25,
      },
    },
    {
      title: "NOMOR NOTA",
      style: {
        width: 50,
      },
    },
    {
      title: "DPP",
      style: {
        width: 24,
      },
    },
    {
      title: "TAX",
      style: {
        width: 17,
      },
    },
    {
      title: "PPH",
      style: {
        width: 17,
      },
    },
    {
      title: "TOTAL",
    },
    {
      title: "KODE",
    },
  ];

  const getCenterPos = (text: string, doc: any) => {
    const pageWidth = doc.internal.pageSize.getWidth();
    const textWidth = doc.getTextWidth(text);
    const centerPos = (pageWidth - textWidth) / 2;
    return centerPos;
  };

  // Data Table
  const dataTable: any[] = [];
  let grandTotal = 0;
  data.forEach((item, index) => {
    let temp: any[] = [];
    temp.push(index + 1); // No
    temp.push(item?.invoice_date || "-"); // Tanggal
    temp.push(item?.invoice_number || "-"); // Nomor Nota
    temp.push(ToMoneyFormat(Math.floor(item?.total))); // DPP
    temp.push(ToMoneyFormat(Math.floor(item?.tax))); // Tax
    temp.push(ToMoneyFormat(Math.floor(item?.pph))); // PPH
    temp.push(ToMoneyFormat(Math.floor(item?.grand_total))); // Total
    temp.push(""); // Kode
    dataTable.push(temp);
    temp = [];
    grandTotal += item?.grand_total || 0;
  });

  // Grand Total
  const grandTotalText: any[] = [
    "",
    "",
    "GRAND TOTAL",
    "",
    "",
    "",
    ToMoneyFormat(Math.floor(grandTotal)),
    "",
  ];
  dataTable.push(grandTotalText);

  const print = (doc: any) => {
    // Variable
    const docWidth = doc.internal.pageSize.width;
    const docHeight = doc.internal.pageSize.height;
    const currentHeight = 15;
    const colorBlack = "#000000";
    const colorGray = "#4d4e53";
    const fieldTextSize = 10;
    const subLineHeight = 5;
    const tdWidth = (doc.getPageWidth() - 20) / header.length;
    const headerTextSize = 20;
    const labelTextSize = 12;
    const lineHeight = 8;

    const pdfConfig = {
      docWidth,
      docHeight,
      currentHeight,
      colorBlack,
      colorGray,
      fieldTextSize,
      subLineHeight,
      header,
      dataTable,
      tdWidth,
      headerTextSize,
      labelTextSize,
      lineHeight,
      addBorder: true,
    };

    // Header Invoice
    doc.setFontSize(14);
    doc.text(
      getCenterPos("TANDA TERIMA", doc),
      pdfConfig.currentHeight,
      "TANDA TERIMA"
    );
    doc.setFontSize(12);
    pdfConfig.currentHeight += 10;
    doc.text(
      pdfConfig.docWidth - 60,
      pdfConfig.currentHeight,
      `Untuk: `,
      "right"
    );
    doc.text(10, pdfConfig.currentHeight, "NOMOR:");
    pdfConfig.currentHeight += 5;

    // Table Header
    addTableHeader({ doc: doc, config: pdfConfig });

    // Table Body
    doc.setFontSize(pdfConfig.fieldTextSize + 1);
    addTableHeaderBorder({ doc, config: { ...pdfConfig } });
    addTableBodyBorder({ doc, config: { ...pdfConfig } });
    drawBody({ doc: doc, config: pdfConfig });

    // Footer Invoice
    pdfConfig.currentHeight = pdfConfig.currentHeight + 20;
    doc.setFontSize(10);
    doc.text(
      pdfConfig.docWidth / 1.4,
      pdfConfig.currentHeight,
      "SURAKARTA,",
      "left"
    );
    pdfConfig.currentHeight += 5;
    doc.text(
      pdfConfig.docWidth / 1.4,
      pdfConfig.currentHeight,
      "HORMAT KAMI,",
      "left"
    );
    doc.text(40, pdfConfig.currentHeight, "YANG MENERIMA,", "right");
    pdfConfig.currentHeight += 5;
    doc.text(
      pdfConfig.docWidth / 1.4,
      pdfConfig.currentHeight,
      "CV TUNAS JAYA TEKNIK",
      "left"
    );
    pdfConfig.currentHeight += 15;
    doc.line(
      60,
      pdfConfig.currentHeight + 8,
      60 - 50,
      pdfConfig.currentHeight + 8
    );
    doc.line(
      210,
      pdfConfig.currentHeight + 7,
      205 - 50,
      pdfConfig.currentHeight + 7
    );
  };

  return { print };
};

export { PDF_TAGIHAN_NON_KODE };
